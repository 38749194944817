body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  height: 32px;
  margin: 16px;

  line-height: 32px;
  color: white;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.4pt;
  font-size: 2rem;

  transition: all 500ms ease;
}

.ant-layout-sider-collapsed .logo {
  font-size: 0.8rem;
  text-align: left;
  margin: 16px 8px;
}

.login-form-button {
  width: 100%;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mr-2 {
  margin-right: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: 1.5rem;
}

.divider {
  margin: 1.5rem 0 1rem 0;
  border-top: 1px solid #aaa;
  width: 100%;
}

h4.companyTitle.ant-typography {
  margin-bottom: 0;
  margin-top: 0;
  margin-right: .2rem;
}

.mac-search.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 4rem;
}
